@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Oxygen:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --main-bg-color: #1E1E1E;
  --border-color: rgb(66, 66, 66);
  --main-color: rgb(0, 168, 246);
  --main-color-transparent: rgba(0, 168, 246, .05);
}

html {
  scroll-behavior: smooth;
}

.span-center-container {
  display: inline-block;
  height: 60px; /* image's height */
}

.span-center-container span {
  height: 60px; /* image's height */
  display: table-cell;
  vertical-align: middle;
}

.sidebarv2 li:hover a.Mui-selected {
  color: inherit!important;
}

.project-example input[type="text"] {
  border:rgb(0, 168, 246) 2px solid;
  padding: 4px;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #282c34;*/
}

.main-content {
  margin: 0px, 50px
}

span.readme-file svg {
  margin-top: 8px
}

span.readme-file span.repo {
  background-color: #e1e1e1;
}

span.readme-file span.file {
  margin-top: 6px;
}

h1 {
  font-weight: 700 !important;
}

h5 {
  font-weight: 600 !important;
}

.header-row .header-col{
  text-align: left;
  color: white;
  background-color: var(--main-bg-color);
}

.header-row h1 {
  margin: 10px 0px;
  text-align: center;
  font-size: 150%;
}

.container {
  display: inline-flex
}

.no-padding {
  padding: 0px !important;
}

.no-side-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.no-right-padding {
  padding-right: 0px !important;
}

.clickable {
  cursor: pointer;
}

.markdown-content {
  padding: 20px 40px;
}

.markdown-content img {
  display: inline;
  vertical-align: bottom;
}

.small-media .markdown-content {
  padding: 15px 8px;
}

.small-media .component-commits .github-scraper-component-content {
  padding: 0px;
}

.small-media .project-commits .github-scraper ul {
  padding: 0px;
}

.markdown {
  margin-top: 20px;
  padding: 0px;
  margin-bottom: 30px;
}

.readme-file {
  display: inline-flex;
}

.left-col {
  padding: 0;
  background-color: var(--main-bg-color);
  height: 100vh;
  color: white;
  overflow: hidden;
}

.family-selection {
  margin-top: 50px;
  text-align: center;
  height: 100%;
}

.family-selection-item {
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 50px;
  margin-right: 50px;
  padding: 20px 20px;
  border-radius: 10px;
}

.family-selection-item:hover {
  background-color: var(--main-color-transparent);
}

.sidebar {
  background-color: var(--main-bg-color);
  color: white;
  overflow-wrap: break-word;
  font-size: 100%;
  height: 90vh;
}

.sidebar.clearer {
  background-color: var(--main-bg-color);
  color: white;
}

.sidebar.empty {
  text-align: center;
  color: grey;
}

.sidebar-item.small-spacing {
  padding: 7px 10px;
  margin: 7px 20px 7px 20px;
  border-radius: 4px;
}

.sidebar-item.large-spacing {
  padding: 10px 20px;
  margin: 20px 40px;
  border-radius: 4px;
  font-weight: 500;
}

.sidebar .separator, .left-col .separator {
  margin: 0px 30px;
  border-bottom: 1px solid var(--border-color);
}

.left-col .separator {
  margin: 0px 90px;
}

.github-scraper .separator {
  margin: 0px 10px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.sidebar-item {
  background-image: linear-gradient(var(--main-color), var(--main-color));
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  margin-left:10px;
  transition:all .25s ease;
}

.sidebar-item.selected {
  background-size: 100% 100%;
}

.sidebar-item:hover {
  background-color: var(--main-color-transparent);    
}

/*.sidebar-item:hover:not(.selected) {
  background-color: var(--main-color-transparent);    
  background: linear-gradient(to left, transparent 50%, var(--main-color) 50%);
  background-size: 200% 100%;
  background-position:left bottom;
  transition:all .6s linear;
}*/

.sidebar-title {
  text-align: left;
  font-weight: bold;
  margin-left: 5px;
  padding-top: 10px;
}

.sidebar-title p {
  color: rgb(197, 197, 197);
}

.sidebar-title.small {
  font-size: 100%;
  letter-spacing: 4px;
  margin-bottom: 15px;
  margin-top: 14px;
  margin-left: 20px;
}

.sidebar-title.small p {
  font-size: 100%;
  margin-bottom: 2px;
}

.sidebar-installation {
  color:rgb(202, 202, 202);
  font-family: 'Source Code Pro', monospace;
  background-color: rgb(36, 35, 35);
  padding: 5px;
  border-radius: 7px;
  border: 3px solid rgb(58, 58, 58);
  margin-right: 20px;
  margin-left: 20px;
  font-size: 90%;
}

.sidebar-installation .copy {
  float: right;
  visibility: hidden;
  margin-top: -2px;
  margin-right: 4px;
}

.sidebar-installation:hover .copy {
  visibility: visible;
}

.header {
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.header .large {
  font-size: 200%;
}

.header .normal {
  font-size: 100%;
}

.readme-page {
  max-height: 100vh;
  overflow-y: scroll;
}

.component-examples {
  /*max-height: 100vh;*/
  max-height: 100vh;
  overflow-y: scroll;
}

.highlighter pre {
  border-radius: 20px !important;
}

.component-examples .example-result {
  padding: 20px 30px;
  min-width: 40%;
  max-width: 50%;
}

.underline {
  border-bottom: 1px solid #dddfe0;
  margin-bottom: 10px;
}

.footer {
  height: 5vh;
  background-color: var(--main-bg-color);
}

.footer-container {
  margin-top: 15px;
  text-align: center;
  color: rgb(226, 226, 226);
  font-size: 90%;
}

.github-scraper-component {
  margin: 30px 0px;
}

.component-languages .github-scraper-component-content {
  width: 80%;
  margin: 0 auto;
}
.project-examples .component-languages .github-scraper-component-content {
  width: 40%;
  min-width: 300px;
  margin: 0 auto;
}

.github-scraper-sidebar {
  padding-left: 20px !important;
 box-shadow: 2px 0px 30px 1px #dadada;
}

.github-scraper-component .github-scraper-component-label {
  margin-bottom: 10px;
  font-size: 110%;
  font-weight: normal;
}

.github-sidebar .github-scraper-component .github-scraper-component-label {
  margin-bottom: 10px;
  font-size: 110%;
  font-weight: bold;
}

.github-scraper-component .github-scraper-component-content {
  padding-left: 15px;
  padding-right: 15px;
}

.github-scraper-component.component-stargazerscount .github-scraper-component-content {
  color: rgb(255, 179, 0);
  font-weight: 700;
  font-size: 150% !important;
}

.contributor-miniature .contributor-miniature-image {
  border: 1px solid #bbbbbb;
  border-radius: 10%;
}

.contributor-miniature .contributor-miniature-image > img {
  border-radius: 10%;
}

.github-scraper-component.component-owneravatar img {
  border-radius: 5%;
}

.project-example.opened {
  margin-bottom: 65px;
}

.project-example-body {
  margin: 0 30px;
}

.project-example h2 {
  cursor: pointer;
}

.project-example .example-open-status {
  transition: "all";
  transition-duration: 100ms;
  transform: rotateZ(0deg);
}


.project-example.opened .example-open-status {
  transition: "all";
  margin-right: 10px;
  transform: rotateZ(90deg) translate(-3px, 0);
}

.project-example .project-example-body {
  /*transition: "max-height";
  transition-duration: 1000ms;*/
  max-height: 0px;
}

.project-example.opened .project-example-body {
  /*transition: "max-height";
  transition-duration: 1000ms;*/
  max-height: 3000px;
}

.unavailable_page_language_text {
  margin-bottom: 10px;
  margin-left: 15px;
}

.unavailable_readme_language_text {
  margin-top: 20px;
  margin-left: 40px;
}

.website-logo {
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.212));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.212));
}

